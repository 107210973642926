import { isProd, PENDO_KEY } from "./env";

if (isProd) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      // @ts-expect-error - TS7034 - Variable 'v' implicitly has type 'any' in some locations where its type cannot be determined.
      var v, w, x, y, z;
      // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'. | TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
      o = p[d] = p[d] || {};
      // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
      o._q = [];
      v = ["initialize", "identify", "updateOptions", "pageLoad"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          o[m] =
            // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
            o[m] ||
            function () {
              // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7005 - Variable 'v' implicitly has an 'any' type.
              o._q[m === v[0] ? "unshift" : "push"](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      // @ts-expect-error - TS2339 - Property 'async' does not exist on type 'HTMLElement'.
      y.async = !0;
      // @ts-expect-error - TS2339 - Property 'src' does not exist on type 'HTMLElement'.
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  })(PENDO_KEY);
}
